<template>
  <div>
    <img src= @/assets/banner.jpg height="200" width="100%" />
    <v-container>
      <div class="space">
        <v-row>
          <v-col sm="12" md="7" xl="7"   data-aos="flip-right">
            <h2>
              Over ons bedrijf Kantoorflex
            </h2>

            <p>
              Werk je vanuit huis? Of op het kantoor van je klant? En heb je
              geen eigen of een voltijds kantoorruimte in Nederland nodig? Bij
              Kantoorflex kun je kiezen tussen onbeperkt gebruik van flexwerken
              in één van onze dynamische flexwerkplek locatie. Of kies voor een
              aantal dagen per maand een meer rustige flexwerkplek te huren in
              een afgesloten kantoor. De keuze is geheel aan jou. In beide
              gevallen staat ons serviceteam van Kantoorflex voor je klaar.
            </p>

            <p>
              Wij verhuren flexplekken door heel Nederland. Flexibel, zonder
              contract en altijd in de buurt.
            </p>

            <v-btn color="info" class="ma-2 white--text" @click="goToContact()">
              Neem contact op
            </v-btn>
          </v-col>
          <v-col>
            <v-card class="mx-auto" max-width="400" elevation="9" shaped>
              <v-img
                class="white--text align-end"
                height="300px"
                src="@/assets/workspace.png"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="space">
          <v-col>
            <v-card class="mx-auto" max-width="400" elevation="9" shaped>
              <v-img
                class="white--text align-end"
                height="300px"
                src="@/assets/why.jpg"
              >
              </v-img>
            </v-card>
          </v-col>
          <v-col sm="12" md="7" xl="7" data-aos="flip-right">
            <h5><b>Wat is een flexibele kantoorruimte?</b></h5>
            Flexibele kantoorruimte voor flexwerken is een allesomvattende term
            voor verschillende soorten werkruimte, waaronder:<br /><br />
            – Gemeubileerde kantoorruimtes<br />
            – Afzonderlijke kantoorruimtes binnen een bedrijf<br />
            – Gedeelde kantoren<br />
            – Collectieve werkruimtes als flexwerkplekken<br /><br />

            <p>
              Wat deze soorten werkruimten 'flexibel' maakt, zijn vooral de
              minimum termijnen. Terwijl je bij traditionele huurcontracten
              meestal voor 5 tot 10 jaar (indien niet meer) moet tekenen, hoef
              je je bij flexibele kantoorruimte veel minder vast te leggen. Bij
              Kantoorflex heb je zelfs maandelijkse doorlopende en opzegbare
              contracten, waardoor je nergens aan vast zit. Heb je een flexplek
              nodig voor 2 maanden of 12 maanden, alles is mogelijk.
            </p>

            <v-btn color="info" class="ma-2 white--text" @click="goToContact()">
              Ik wil meer informatie
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="space">
          <v-col sm="12" md="7" xl="7" data-aos="flip-left">
            <h5><b>Flexplek aanmelden</b></h5>
            <p>
              Heb jij als bedrijf een flexwerkplek over en wil je deze verhuren
              via Kantoorflex? Neem dan zeker contact met ons op en meld jouw
              flexwerkplek aan op onze website. Waarom een ongebruikte ruimte
              niet verhuren aan flexwerkers? Flexwerken is niet meer weg te
              denken in ons dagelijkse zakelijke leven. Meld jouw flexwerkplek
              nu aan op de website van Kantoorflex.
            </p>
            <v-btn color="info" class="ma-2 white--text" @click="goToAccount()">
              Flexplek toevoegen
            </v-btn>
          </v-col>
          <v-col>
            <v-card class="mx-auto" max-width="400" elevation="9" shaped>
              <v-img
                class="white--text align-end"
                height="300px"
                src="@/assets/flexplekaanmelden.png"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  methods: {
    goToContact() {
      this.$router.push("/Contact");
    },
    goToAccount() {
      this.$router.push("/Inloggen");
    },
  },
};
</script>

<style scoped>
.space {
  margin-top: 15px;
  margin-bottom: 8px;
  border-style: none;
}
</style>

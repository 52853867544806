<template>
<v-container>
  <div v-if="paymentStatus === 'paid'">
 <h1>Bedankt voor uw betaling!</h1>
    <p>Uw betaling is succesvol verwerkt.</p>
    <p>Reservation number: {{ id }}</p>
  </div>
  <div v-else>
    <p>Payment was not successful.</p>
  </div>
</v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      paymentStatus: null,
      reservationNumber: null,
    };
  },
  async created() {
    const paymentId = new URLSearchParams(window.location.search).get('id');
    const response = await axios.get(`/api/payments/${paymentId}`);
    this.paymentStatus = response.data.status;
    this.reservationNumber = response.data.reservationNumber;
  },
   mounted() {
    // Na 5 seconden doorverwijzen naar de hoofdpagina
    setTimeout(() => {
      this.redirectToMainPage();
    }, 5000);
  },
  methods: {
    redirectToMainPage() {
      this.$router.push('/');
    },
  },
};
</script>

<template>
  <ContactPage />
</template>

<script>
import ContactPage from "@/components/view/ContactPage";

export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    ContactPage,
  },
  metaInfo: {
    title: "Welkom bij KantoorFlex | flexplekken in Nederland ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
     meta: [
      { charset: "utf-8" },
      {
        name: "description",
        content:
          "Neem contact op met kantoorflex Nederland.",
      },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>